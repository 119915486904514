.media-page {
  height: 100vh;
  width: 100vw;
  background-color: #000000;
  .media-page-background {
    background: url("../../../images/meida-background.jpg") no-repeat center
      center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    overflow: auto;
    .react-photo-gallery--gallery {
      margin: 80px 100px 30px 100px;
      cursor: pointer;
      border-radius: 30px;
      img {
        margin: 20px;
      }
      @media screen and (max-width: 620px) {
        margin: 80px 30px 30px 30px;
      }
      @media screen and (max-width: 420px) {
        margin: 80px 15px 30px 15px;
      }
    }
  }
}
.react-images__blanket.css-16jr3vd.css-1ycyyax {
  background: #000000;
}
