* {
  margin: 0px;
  font-family: "Chinese Rocks Rg", sans-serif;
  overflow: hidden;
  .info-content {
    overflow: auto;
  }
  .pages {
    display: block;
    overflow: hidden;
    &.active {
      position: absolute;
      display: flex;
      &.horizontal-scrolling {
        flex-direction: column;
      }
    }
    .youtube-music-player {
      opacity: 0;
    }
  }
}
