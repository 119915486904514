@import url("http://fonts.cdnfonts.com/css/chinese-rocks");

* {
  .start-page {
    text-align: center;
    position: relative;
    cursor: pointer;
    background: url("../../images/start-page-background.jpg") no-repeat center
      center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    overflow: hidden;
    display: flex;
    height: 100vh;
    width: 100vw;
    z-index: 999;
    &.displayNone {
      display: none;
    }
    .kattints-felirat {
      background-color: #d00c16af;
      color: #ffffff;
      font-weight: 900;
      text-transform: uppercase;
      z-index: 1000;
      display: flex;
      justify-content: center;
      border-radius: 15px;
      align-items: center;
      font-size: 18px;
      position: absolute;
      padding-left: 10px;
      animation: kattintsFelirat1 1s 0.5s ease-out forwards,
        kattintsFelirat2 3s 0s ease-out infinite;
      opacity: 0;
      width: 250px;
      height: 60px;
      margin: auto auto;
      bottom: 0%;
      right: 0%;
      left: 0%;
      &:hover {
        background-color: #d32e3611;
        color: #e49a12;
      }
      .pointer-icon {
        margin-left: 20px;
        transform: scale(1.2);
      }
    }
    .lovas-background {
      transform: translateY(2px);
      animation: felLeMozgas 3s 0s ease-in-out infinite,
        elotunes 2s 0s ease-out forwards;
      background: url("../../images/start-page-lovas-zaszlo.png") no-repeat
        center bottom fixed;
      width: 100%;
    }
    .cim-background {
      transform: translateX(-500px);
      animation: cimSlideRight 1s 0.3s ease-out forwards;
      background: url("../../images/start-page-cim.png") no-repeat center top
        fixed;
    }
    .foszereplo-background {
      transform: translateX(500px);
      animation: foszereploSlideLeft 1s 0.7s ease-out forwards,
        jobbraBalraMozgas 4s 1.5s ease-in-out infinite;
      background: url("../../images/start-page-foszereplo.png") no-repeat center
        bottom fixed;
    }
    .lovas-background,
    .cim-background,
    .foszereplo-background {
      opacity: 0;
      text-align: center;
      position: absolute;
      -webkit-background-size: contain;
      -moz-background-size: cover;
      -o-background-size: contain;
      background-size: contain;
      display: flex;
      height: 100vh;
      width: 100%;
    }
    &.deactivated {
      animation: eltunes 1s 1s ease-out forwards;
      .cim-background {
        opacity: 1;
        transform: translateX(0px);
        animation: cimSlideLeft 1s 0.3s ease-out forwards;
      }
      .foszereplo-background {
        opacity: 1;
        animation: foszereploSlideRight 1s 0.7s ease-out forwards,
          jobbraBalraMozgas 4s 1.5s ease-in-out infinite;
      }
      .lovas-background {
        transform: translateY(0px);
        animation: felLeMozgas 3s 0s ease-in-out infinite,
          eltunes 2s 0s ease-out forwards;
      }
      .kattints-felirat {
        animation: eltunes 2s 0.1s ease-out forwards;
      }
    }

    @media screen and (max-width: 700px) {
      .cim-background {
        background: url("../../images/start-page-cim-mobile.png") no-repeat
          center top fixed;
        -webkit-background-size: contain;
        -moz-background-size: cover;
        -o-background-size: contain;
        background-size: contain;
      }
    }
    @media screen and (min-width: 1200px) {
      .lovas-background {
        background-size: cover;
      }
    }
  }
}

@keyframes felLeMozgas {
  0% {
    transform: translateY(4px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(4px);
  }
}
@keyframes elotunes {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes eltunes {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes jobbraBalraMozgas {
  0% {
    transform: translatex(5px);
  }
  50% {
    transform: translatex(0px);
  }
  100% {
    transform: translatex(5px);
  }
}
@keyframes foszereploSlideLeft {
  0% {
    transform: translateX(500px);
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes foszereploSlideRight {
  0% {
    transform: translateX(0px);
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: translateX(500px);
    opacity: 0;
  }
}
@keyframes cimSlideRight {
  0% {
    transform: translateX(-500px);
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes cimSlideLeft {
  0% {
    transform: translateX(0px);
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: translateX(-500px);
    opacity: 0;
  }
}
@keyframes kattintsFelirat1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes kattintsFelirat2 {
  0% {
    transform: rotate(0deg);
  }
  7% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(1deg);
  }
  13% {
    transform: rotate(-0.5deg);
  }
  16% {
    transform: rotate(-0.5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
