.home-page {
  text-align: center;
  position: relative;
  display: flex;
  height: 100vh;
  min-width: 100vw;
  background-color: white;
  justify-content: center;
  align-items: center;
  .youtube-music-player {
    position: absolute;
    opacity: 0;
  }
  .home-page-packground {
    opacity: 0;
    text-align: center;
    position: relative;
    background: url("../../../images/tabortuz.jpg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-size: 100px;
    display: flex;
    height: 100vh;
    width: 100%;
    color: white;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &.activated {
      animation: elotunes 2s 0s ease-out forwards;
    }
    .cim-box {
      display: flex;
      width: 100%;
      justify-content: center;
      @media screen and (max-width: 650px) {
        max-width: 300px;
        .cim {
          display: flex;
          width: 100%;
          justify-content: center;

          font-size: 70px;
        }
      }
    }
    .alcim {
      font-size: 30px;
      font-weight: 200;
      padding: 15px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      .arrow {
        background: url("../../../images/fooldal-nyil.png") no-repeat center;
        text-align: center;
        position: relative;
        animation: elotunes 2s 7s ease-out forwards;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        display: flex;
        height: 20px;
        width: 200px;
        color: white;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &:first-of-type {
          transform: rotate(180deg);
          margin-right: 10px;
        }
        &:last-of-type {
          margin-left: 10px;
        }
        @media screen and (max-width: 693px) {
          margin: 0px;
        }
        @media screen and (max-width: 580px) {
          display: none;
        }
      }
      .alcim-szoveg {
        @media screen and (max-width: 693px) {
          width: 160px;
        }
      }
    }
    .discord-gomb {
      background: url("../../../images/fooldal-piros-gomb.png") no-repeat center;
      text-align: center;
      position: relative;
      animation: elotunes 2s 7s ease-out forwards;
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;
      text-decoration: none;
      overflow: hidden;
      display: flex;
      height: 90px;
      width: 380px;
      transform: scale(0.78);
      color: white;
      font-size: 50px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      transition: 0.2s ease-out;
      cursor: pointer;
      &:hover {
        transform: scale(0.8);
        color: rgb(34, 34, 34);
      }
    }
  }
}

@keyframes elotunes {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
