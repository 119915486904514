.navbar-section {
  display: flex;
  position: absolute;
  flex-direction: column;
  text-align: center;
  position: fixed;
  height: 100px;
  min-width: 100vw;
  .navbar {
    height: 100px;
    display: flex;
    transition: all 0.2s ease-out;
    .navbar-logo {
      margin: -12px 0px 0px -5px;
      background: url("../../images/logo.png") no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      cursor: pointer;
      height: 100px;
      width: 100px;
      transform: scale(0.7);
      &:hover {
        background: url("../../images/logo2.png") no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
      }
    }
    .navbar-line {
      margin-top: 37px;
      height: 2px;
      border-radius: 50%;
      background-color: #ffffff50;
      flex: auto;
    }
    .navbar-menu {
      display: flex;
      .menu-item {
        position: relative;
        padding: 30px 20px;
        cursor: pointer;
        overflow: hidden;
        color: #ffffff;
        font-size: 18px;
        .navbar-background {
          transition: all 0.2s ease-out;
          position: absolute;
          background: url("../../images/navbar-background.png") no-repeat center
            center;
          -webkit-background-size: cover;
          -moz-background-size: cover;
          -o-background-size: cover;
          background-size: cover;
          margin-top: -42px;
          height: 100px;
          display: none;
          z-index: -1;
          opacity: 0.5;
          width: 50px;
          transform: scale(2.5);
          &.masodik {
            transform: scale(3.2);
            margin: -45px 0px 0px 12px;
            transition: all 0.2s ease-out;
          }
          &.harmadik {
            transform: scale(1.8);
            margin-left: -15px;
          }
          &.negyedik {
            transform: scale(1.8);
            margin-left: -10px;
          }
        }
        &:hover {
          color: #e49a12;
          .navbar-background {
            display: block;
            opacity: 0.5;
          }
        }
        &.active.activated,
        &.first {
          .navbar-background {
            display: block;
            opacity: 1;
          }
          &:hover {
            .navbar-background {
              display: block;
              color: #000000;
              background: url("../../images/navbar-background-red.png")
                no-repeat center center;
              -webkit-background-size: cover;
              -moz-background-size: cover;
              -o-background-size: cover;
              background-size: cover;
              &.elso {
                transform: scale(2.7);
              }
              &.masodik {
                transform: scale(3.4);
              }
              &.harmadik {
                transform: scale(2);
              }
              &.negyedik {
                transform: scale(2);
              }
            }
          }
        }
        &:last-of-type.active.activated {
          .navbar-background {
            display: block;
            color: #000000;
            background: url("../../images/navbar-background-red.png") no-repeat
              center center;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
          }
        }
      }
      @media screen and (max-width: 650px) {
        background: #000000dc;
        width: 100vw;
        height: 100vh;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: fixed;
        transform: translate(600px);
        opacity: 1;
        &.is-open {
          animation: openmenu 0.4s 0s ease-in-out forwards;
        }
        &.is-close {
          animation: closemenu 0.4s 0s ease-in-out forwards;
        }
        .menu-item {
          width: 100%;
          font-size: 27px;
          &:hover {
            background: #d00c168c;
            .navbar-background {
              display: none;
            }
            &.active.activated,
            &.first {
              .navbar-background {
                display: none;
              }
            }
          }
          &.active.activated,
          &.first {
            background: #d00c1679;
            &:hover {
              background: #d00c16af;
            }
            .navbar-background {
              display: none;
            }
          }
          &:last-of-type.active.activated {
            .navbar-background {
              display: none;
            }
          }
        }
      }
    }
    .navbar-top-right {
      position: relative;
      padding: 27px 20px;
      color: #ffffff;
      font-size: 18px;
      display: flex;
      .hamburger {
        display: none;
      }
      .next {
        position: absolute;
        display: none;
        right: 0px;
        top: 5px;
        &:hover {
          color: #d00c16;
        }
      }
      .spinner {
        color: #ffffff;
        position: absolute;
        //opacity: 0.2;
        font-size: 50px;
        right: 7px;
        top: 15px;
      }
      .spinner.active {
        animation: forgas 12s 0s linear infinite;
      }
      .pause-button,
      .play-button {
        font-size: 25px;
        cursor: pointer;
      }
      &:hover {
        .pause-button,
        .play-button {
          color: #e49a12;
        }
      }
      &:active {
        .pause-button,
        .play-button {
          color: #d00c16;
        }
      }
      @media screen and (max-width: 650px) {
        padding-right: 20px;
        margin-right: 70px;
        overflow: initial;
        .hamburger {
          display: block;
          cursor: pointer;
          position: absolute;
          top: 15px;
          right: -55px;
          &:hover {
            color: #e49a12;
          }
          &:active {
            color: #d00c16;
          }
        }
        .spinner {
          right: 7px;
        }
      }
    }
  }
  .navbar-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .navbar-line {
      margin: 0px 0px 10px 10px;
      height: 2px;
      border-radius: 50%;
      background-color: #ffffff50;
      flex: auto;
    }
    .facebook-icon {
      font-size: 20px;
      color: #ffffff;
      height: 30px;
      width: 30px;
      margin: 0px 10px 10px 15px;
      transition: all 0.2s ease-out;
      cursor: pointer;
      &:hover {
        color: #e49a12;
      }
      &:active {
        color: #d00c16;
      }
    }
  }
}

@keyframes forgas {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes openmenu {
  0% {
    transform: translate(600px);
    opacity: 0;
  }
  100% {
    transform: translate(0px);
    opacity: 1;
  }
}
@keyframes closemenu {
  0% {
    transform: translate(0px);
    opacity: 1;
  }
  100% {
    transform: translate(600px);
    opacity: 0;
  }
}
